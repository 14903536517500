const viaCepService = async (zipcode) => {
  const response = await window.fetch(
    `https://viacep.com.br/ws/${zipcode}/json/`
  );

  const result = await response.json();

  return [response.ok, result];
};

export default viaCepService;
